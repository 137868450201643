import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
  },
  {
    path: '/product/contract',
    name: 'Contract',
    component: () => import('@/views/product/Contract.vue'),
  },
  {
    path: '/product/order',
    name: 'Order',
    component: () => import('@/views/product/Order.vue'),
  },
  {
    path: '/product/project',
    name: 'Project',
    component: () => import('@/views/product/Project.vue'),
  },
  {
    path: '/product/labor',
    name: 'Labor',
    component: () => import('@/views/product/Labor.vue'),
  },
  {
    path: '/product/debt',
    name: 'Debt',
    component: () => import('@/views/product/Debt.vue'),
  },
  {
    path: '/product/archive',
    name: 'Archive',
    component: () => import('@/views/product/Archive.vue'),
  },
  {
    path: '/product/file',
    name: 'File',
    component: () => import('@/views/product/File.vue'),
  },
  {
    path: '/product/unit',
    name: 'Unit',
    component: () => import('@/views/product/Unit.vue'),
  },
  {
    path: '/product/chapter',
    name: 'Chapter',
    component: () => import('@/views/product/Chapter.vue'),
  },
  {
    path: '/product/report',
    name: 'Report',
    component: () => import('@/views/product/Report.vue'),
  },
  {
    path: '/solution/finance',
    name: 'Finance',
    component: () => import('@/views/solution/Finance.vue'),
  },
  {
    path: '/solution/government',
    name: 'Government',
    component: () => import('@/views/solution/Government.vue'),
  },
  {
    path: '/solution/manufacture',
    name: 'Manufacture',
    component: () => import('@/views/solution/Manufacture.vue'),
  },
  {
    path: '/solution/internet',
    name: 'Internet',
    component: () => import('@/views/solution/Internet.vue'),
  },
  {
    path: '/customer',
    name: 'Customer',
    component: () => import('@/views/Customer.vue'),
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News.vue'),
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('@/views/Detail.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
  },
  {
    path: '/open',
    name: 'Open',
    component: () => import('@/views/Open.vue'),
  },
  {
    path: '/ad',
    name: 'ad',
    component: () => import('@/views/Ad.vue'),
  },
  {
    path: '/group',
    name: 'group',
    redirect: '/ad',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
